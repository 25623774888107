/* latin-ext */
@font-face {
  font-family: "Libre Baskerville";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/libre-baskerville-style-italic-weight-400-latin-ext.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/libre-baskerville-style-italic-weight-400-latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/libre-baskerville-style-normal-weight-400-latin-ext.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fonts/libre-baskerville-style-normal-weight-400-latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../assets/fonts/libre-baskerville-style-normal-weight-700-latin-ext.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../assets/fonts/libre-baskerville-style-normal-weight-700-latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../assets/fonts/montserrat-style-normal-weight-500-latin-ext.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../assets/fonts/montserrat-style-normal-weight-500-latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../assets/fonts/montserrat-style-normal-weight-700-latin-ext.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
    U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../assets/fonts/montserrat-style-normal-weight-700-latin.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* CLS safe fallbacks */
@font-face {
  font-family: "Libre-Baskerville-fallback";
  font-style: normal;
  font-weight: 400;
  size-adjust: 115%;
  src: local("Georgia");
}

@font-face {
  font-family: "Libre-Baskerville-fallback";
  font-style: italic;
  font-weight: 400;
  size-adjust: 105%;
  src: local("Georgia Italic");
}

@font-face {
  font-family: "Libre-Baskerville-fallback";
  font-style: normal;
  font-weight: 700;
  size-adjust: 115%;
  src: local("Georgia");
}

@font-face {
  font-family: "Montserrat-fallback";
  font-style: normal;
  font-weight: 700;
  size-adjust: 95%;
  src: local("Verdana Bold");
}

@font-face {
  font-family: "Montserrat-fallback";
  font-style: normal;
  font-weight: 400;
  size-adjust: 105%;
  src: local("Verdana");
}
